<script>
/* eslint-disable */
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

/**
 * Carousel component
 */
export default {
  page: {
    title: 'Carousel',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {Layout, PageHeader},
  data() {
    return {
      title: 'Carousel',
      items: [
        {
          text: 'UI Elements',
          href: '/',
        },
        {
          text: 'Carousel',
          active: true,
        },
      ],
      slide: 0,
      slide1: 0,
      sliding: null,
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">

            <h4 class="card-title">Slides only</h4>
            <p class="card-title-desc">Here’s a carousel with slides only.
              Note the presence of the <code>.d-block</code>
              and <code>.img-fluid</code> on carousel images
              to prevent browser default image alignment.</p>

            <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-interval="3000"
                 data-bs-ride="carousel">
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <img alt="First slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-1.jpg">
                </div>
                <div class="carousel-item">
                  <img alt="Second slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-2.jpg">
                </div>
                <div class="carousel-item">
                  <img alt="Third slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-3.jpg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- end col -->

      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">

            <h4 class="card-title">With controls</h4>
            <p class="card-title-desc">Adding in the previous and next controls:</p>

            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <img alt="First slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-4.jpg">
                </div>
                <div class="carousel-item">
                  <img alt="Second slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-5.jpg">
                </div>
                <div class="carousel-item">
                  <img alt="Third slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-6.jpg">
                </div>
              </div>
              <a class="carousel-control-prev" data-bs-slide="prev" href="#carouselExampleControls"
                 role="button">
                <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" data-bs-slide="next" href="#carouselExampleControls"
                 role="button">
                <span aria-hidden="true" class="carousel-control-next-icon"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div> <!-- end col -->
    </div> <!-- end row -->

    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">

            <h4 class="card-title">With indicators</h4>
            <p class="card-title-desc">You can also add the indicators to the
              carousel, alongside the controls, too.</p>

            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
              <ol class="carousel-indicators">
                <li class="active" data-bs-slide-to="0"
                    data-bs-target="#carouselExampleIndicators"></li>
                <li data-bs-slide-to="1" data-bs-target="#carouselExampleIndicators"></li>
                <li data-bs-slide-to="2" data-bs-target="#carouselExampleIndicators"></li>
              </ol>
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <img alt="First slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-3.jpg">
                </div>
                <div class="carousel-item">
                  <img alt="Second slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-2.jpg">
                </div>
                <div class="carousel-item">
                  <img alt="Third slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-1.jpg">
                </div>
              </div>
              <a class="carousel-control-prev" data-bs-slide="prev"
                 href="#carouselExampleIndicators"
                 role="button">
                <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" data-bs-slide="next"
                 href="#carouselExampleIndicators"
                 role="button">
                <span aria-hidden="true" class="carousel-control-next-icon"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div> <!-- end col -->

      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">

            <h4 class="card-title">With captions</h4>
            <p class="card-title-desc">Add captions to your slides easily with the <code>.carousel-caption</code>
              element within any <code>.carousel-item</code>.</p>

            <div id="carouselExampleCaption" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <img alt="..." class="d-block img-fluid" src="@/assets/images/small/img-7.jpg">
                  <div class="carousel-caption d-none d-md-block text-white-50">
                    <h5 class="text-white">First slide label</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img alt="..." class="d-block img-fluid" src="@/assets/images/small/img-5.jpg">
                  <div class="carousel-caption d-none d-md-block text-white-50">
                    <h5 class="text-white">Second slide label</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  </div>
                </div>
                <div class="carousel-item">
                  <img alt="..." class="d-block img-fluid" src="@/assets/images/small/img-4.jpg">
                  <div class="carousel-caption d-none d-md-block text-white-50">
                    <h5 class="text-white">Third slide label</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  </div>
                </div>
              </div>
              <a class="carousel-control-prev" data-bs-slide="prev" href="#carouselExampleCaption"
                 role="button">
                <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" data-bs-slide="next" href="#carouselExampleCaption"
                 role="button">
                <span aria-hidden="true" class="carousel-control-next-icon"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div> <!-- end col -->
    </div> <!-- end row -->

    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Crossfade</h4>
            <p class="card-title-desc">Add <code>.carousel-fade</code> to your carousel to animate
              slides with a fade transition instead of a slide.</p>
            <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
              <ol class="carousel-indicators">
                <li class="active" data-bs-slide-to="0" data-bs-target="#carouselExampleFade"></li>
                <li data-bs-slide-to="1" data-bs-target="#carouselExampleFade"></li>
                <li data-bs-slide-to="2" data-bs-target="#carouselExampleFade"></li>
              </ol>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img alt="First slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-1.jpg">
                </div>
                <div class="carousel-item">
                  <img alt="Second slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-2.jpg">
                </div>
                <div class="carousel-item">
                  <img alt="Third slide" class="d-block img-fluid"
                       src="@/assets/images/small/img-3.jpg">
                </div>
              </div>
              <a class="carousel-control-prev" data-bs-slide="prev" href="#carouselExampleFade"
                 role="button">
                <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" data-bs-slide="next" href="#carouselExampleFade"
                 role="button">
                <span aria-hidden="true" class="carousel-control-next-icon"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
